import { useNavigate } from 'react-router-dom';
import assetImages from '../../constant/enum';
import './Footer.css';

const Footer = () => {

    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    }
    return <footer className='footer-section'>
        <div className='footer-container'>
            <div className='footer-logo-row'>
                <div>
                    <img src={assetImages.footerlogo} alt="img" />
                </div>
                <div>
                    <span className='footer-link' onClick={() => { handleNavigate('/'); }}>Home</span>
                    <span className='footer-link' onClick={() => { handleNavigate('/about-us'); }}>About</span>
                    <span className='footer-link' onClick={() => { handleNavigate('/services'); }}>Services</span>
                    <span className='footer-link' onClick={() => { handleNavigate('/projects'); }}>Our Work</span>
                    <span className='footer-link mr-0' onClick={() => { handleNavigate('/faq'); }}>Faq</span>
                </div>
            </div>
            <div className='copy-right-row'>
                <div><span className='copy-right-text'>Copyright©skyblue. All right reserved</span></div>
                <div className='footer-icon'>
                    <a href='https://www.skyblueinfotech.com/' target='_blank'>
                    <img src={assetImages.google} alt="img" />
                    </a>
                    {/* <a>
                    <img src={assetImages.twitter} alt="img" />
                    </a> */}
                     <a href='https://www.facebook.com/Skytraveller1997?mibextid=ZbWKwL' target='_blank'>
                        <img src={assetImages.facebook} alt="img" className='icon-right-space' />
                    </a>
                    <a href='https://instagram.com/skyblue_infotech?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D'  target='_blank'>
                    <img src={assetImages.insta} alt="img" />
                    </a>
                    <a href='https://www.linkedin.com/company/skyblue-infotech/' target='_blank'>
                    <img src={assetImages.linkdin} alt="img" className='mr-0' />
                    </a>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer;