import React, { useEffect } from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactUsComp from "../../components/ContactUsComp/ContactUsComp";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Header />
            <ContactUsComp />
            <Footer />
            <ScrollToTop />
        </>
    )
}

export default ContactUs;