import logo from '../assets/images/header-logo.png'
import menuicon from '../assets/images/menu.png'
import closeicon from '../assets/images/closeicon.png';
import solution1 from '../assets/images/solution1.png'
import solution2 from '../assets/images/solution2.png'
import solution3 from '../assets/images/solution3.png'
import solution4 from '../assets/images/solution4.png'
import solution5 from '../assets/images/solution5.png'
import creativeshape from '../assets/images/creative-shape.png'
import creativearrow from '../assets/images/creative-arrow.png'
import line1 from '../assets/images/line1.png'
import line2 from '../assets/images/line2.png'
import whoweare from '../assets/images/whoweare.png'
import countbg from '../assets/images/count-bg.png'
import digitalmarketing from '../assets/images/digital-marketing.png'
import uiux from '../assets/images/ui-ux.png'
import webdevelopment from '../assets/images/web-development.png'
import digital from '../assets/images/digital.png'
import ansible from '../assets/images/ansible.png'
import chef from '../assets/images/chef.png'
import puppet from '../assets/images/puppet.png'
import se from '../assets/images/se.png'
import kubernets from '../assets/images/kubernets.png'
import bitbucket from '../assets/images/bitbucket.png'
import docker from '../assets/images/docker.png'
import github from '../assets/images/github.png'
import gitlab from '../assets/images/gitlab.png'
import jenkins from '../assets/images/jenkins.png'
import ecom from '../assets/images/ecom.png'
import pos from '../assets/images/pos.png'
import arvr from '../assets/images/arvr.png'
import healthcare from '../assets/images/healthcare.png'
import footerlogo from '../assets/images/footer-logo.png'
import google from '../assets/images/google.png'
import twitter from '../assets/images/twitter.png'
import insta from '../assets/images/insta.png'
import linkdin from '../assets/images/linkdin.png'
import call from '../assets/images/call.png'
import facebook from '../assets/images/facebook.png'
import mail from '../assets/images/mail.png'
import MapPoint from '../assets/images/MapPoint.png'
import plus from '../assets/images/plus.png'
import minus from '../assets/images/minus.png'
import project1 from '../assets/images/project1.png'
import p1 from '../assets/images/p1.png'
import project2 from '../assets/images/project2.png'
import project3 from '../assets/images/project3.png'
import servicemail from '../assets/images/service-mail.png'
import aboutus01 from '../assets/images/about-us-01.png'
import aboutus02 from '../assets/images/about-us-02.png'
import aboutus03 from '../assets/images/about-us-03.png'
import aboutlogo1 from '../assets/images/about-logo-1.png'
import aboutlogo2 from '../assets/images/about-logo-2.png'
import aboutlogo3 from '../assets/images/about-logo-3.png'
import aboutlogo4 from '../assets/images/about-logo-4.png'
import aboutusicon01 from '../assets/images/about-us-icon-01.svg'
import aboutusicon02 from '../assets/images/about-us-icon-02.svg'
import ab1 from '../assets/images/ab1.png'
import ab2 from '../assets/images/ab2.png'
import t1 from '../assets/images/t1.png'
import quote from '../assets/images/quote.png'
import OurFeatured from '../assets/images/OurFeatured.png'
import WhatWeDo from '../assets/images/WhatWeDo.png'
import arvr1 from '../assets/images/arvr1.png'
import arvr2 from '../assets/images/arvr2.png'
import arvr3 from '../assets/images/arvr3.png'
import ba1 from '../assets/images/ba1.png'
import ba2 from '../assets/images/ba2.png'
import ba3 from '../assets/images/ba3.png'
import ba4 from '../assets/images/ba4.png'
import ba5 from '../assets/images/ba5.png'
import ba6 from '../assets/images/ba6.png'
import ba7 from '../assets/images/ba7.png'
import ba8 from '../assets/images/ba8.png'
import fa1 from '../assets/images/fa1.png'
import fa2 from '../assets/images/fa2.png'
import fa3 from '../assets/images/fa3.png'
import fa4 from '../assets/images/fa4.png'
import fa5 from '../assets/images/fa5.png'
import fa6 from '../assets/images/fa6.png'
import fa7 from '../assets/images/fa7.png'
import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
import img3 from '../assets/images/img3.png'
import img4 from '../assets/images/img4.png'
import img5 from '../assets/images/img5.png'
import img6 from '../assets/images/img6.png'
import img7 from '../assets/images/img7.png'
import img8 from '../assets/images/img8.png'
import img9 from '../assets/images/img9.png'
import img10 from '../assets/images/img10.png'
import img11 from '../assets/images/img11.png'
import img12 from '../assets/images/img12.png'
import img13 from '../assets/images/img13.png'
import img14 from '../assets/images/img14.png'
import img15 from '../assets/images/img15.png'
import img16 from '../assets/images/img16.png'
import dg from '../assets/images/dg.png'
import ar from '../assets/images/ar.png'
import appli from '../assets/images/appli.png'

import sec2_1 from '../assets/images/sec2_1.svg'
import sec2_2 from '../assets/images/sec2_2.svg'
import sec2_3 from '../assets/images/sec2_3.svg'
import sec2_4 from '../assets/images/sec2_4.svg'
import sec2_5 from '../assets/images/sec2_5.svg'

const assetImages = {
    logo,
    menuicon,
    closeicon,
    solution1,
    solution2,
    solution3,
    solution4,
    creativeshape,
    creativearrow,
    solution5,
    line1,
    line2,
    whoweare,
    countbg,
    webdevelopment,
    uiux,
    digitalmarketing,
    digital,
    ansible,
    chef,
    puppet,
    se,
    kubernets,
    bitbucket,
    docker,
    github,
    gitlab,
    jenkins,
    ecom,
    pos,
    arvr,
    healthcare,
    footerlogo,
    google,
    twitter,
    insta,
    linkdin,
    call,
    facebook,
    mail,
    MapPoint,
    minus,
    plus,
    project1,
    p1,
    project2,
    project3,
    servicemail,
    aboutus01,
    aboutus02,
    aboutus03,
    aboutlogo1,
    aboutlogo2,
    aboutlogo3,
    aboutlogo4,
    aboutusicon01,
    aboutusicon02,
    ab1,
    ab2,
    t1,
    quote,
    OurFeatured,
    WhatWeDo,
    arvr3,
    arvr2,
    arvr1,
    ba1,
    ba2,
    ba3,
    ba4,
    ba5,
    ba6,
    ba7,
    ba8,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    dg,
    ar,
    appli,
    sec2_1,
    sec2_2,
    sec2_3,
    sec2_4,
    sec2_5,

}

export default assetImages;