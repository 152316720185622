import {
    BrowserRouter as Router,
    Routes,
    Route,
    HashRouter
} from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import ContactUs from "./Pages/ContactUs";
import Faq from "./Pages/Faq";
import OurProject from "./Pages/OurProject";
import Services from "./Pages/Services";
import AnimatedCursor from "react-animated-cursor"
import AboutUs from "./Pages/AboutUs";
const Home = React.lazy(() => import('./Pages/Home/index'));
const CustomRoutes = () => {
    const useMousePosition = () => {
        const [
            mousePosition,
            setMousePosition
        ] = React.useState({ x: null, y: null });

        React.useEffect(() => {
            const updateMousePosition = ev => {
                setMousePosition({ x: ev.clientX, y: ev.clientY });
            };

            window.addEventListener('mousemove', updateMousePosition);

            return () => {
                window.removeEventListener('mousemove', updateMousePosition);
            };
        }, []);

        return mousePosition;
    };
    const mousePosition = useMousePosition();

    return (
        <>
            {/* <div className="cursor1" style={{ top: mousePosition?.y, left: mousePosition?.x }}></div>
            <div className="cursor2" style={{ top: mousePosition?.y, left: mousePosition?.x }}></div> */}
            {/* {console.log("dsjsdgh", JSON.stringify(mousePosition))} */}
            <AnimatedCursor innerSize={10}
                outerSize={40}
                innerStyle={{
                    backgroundColor: '#425264',
                    zIndex: 9999
                }}
                outerStyle={{
                    border: '1px solid #285EA8',
                    backgroundColor: "transparent",
                    maxWidth: '40px',
                    maxHeight: '40px',
                    zIndex: 9999
                }} />
            <Router>
                <Routes>
                    {/* <Route path="/" element={<Suspense fallback={<><Loader /></>}><Main /></Suspense>} /> */}
                    <Route path="/" element={<Suspense fallback={<></>}><Home /></Suspense>} />
                    <Route path="/contact-us" element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />
                    <Route path="/faq" element={<Suspense fallback={<></>}><Faq /></Suspense>} />
                    <Route path="/projects" element={<Suspense fallback={<></>}><OurProject /></Suspense>} />
                    <Route path="/services" element={<Suspense fallback={<></>}><Services /></Suspense>} />
                    <Route path="/about-us" element={<Suspense fallback={<></>}><AboutUs /></Suspense>} />
                </Routes>
            </Router>
        </>
    )
}

export default CustomRoutes;