import { useEffect } from 'react';
import './GreatTogether.css'
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
const GreatTogether = () => {


    useEffect(() => {
        Aos.init();
    }, [])
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate('/contact-us');
    }
    return (
        <section className='great-toghther-section'>
            <div className='great-toghther-div'>
                <div >
                    <h2 className='great-toghther-title'>Let’s make something great together</h2>
                    <div className='get-free-quotes justify-center'>
                        <div id="btn_wrapper-get" onClick={handleNavigate}>
                            <div className='custom-round-btn btn-item'>
                                <span className='demo'></span>Get Free
                                <br />Quotes
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.54556 9L0 7.45499L5.2688 2.18801L0.757109 2.188L0.757125 2.90031e-05L9 0L8.99995 8.24616L6.81733 8.24613L6.81736 3.72995L1.54556 9Z" fill="#425264" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GreatTogether;