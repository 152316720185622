import logo from './logo.svg';
import './App.css';
import './assets/css/bootstrap.min.css'
import 'aos/dist/aos.css';
import CustomRoutes from './CustomRoutes'

function App() {
  return <CustomRoutes />;
}

export default App;
