import { useEffect, useState } from 'react';
import assetImages from '../../constant/enum';
import './AboutOne.css';
import Aos from 'aos';
import Testimonial from '../Testimonial/Testimonial';
import GetInTouchMarquee from '../GetInTouchMarquee/GetInTouchMarquee';
import GreatTogether from '../GreatTogether/GreatTogether';
import Footer from '../Footer/Footer';
const AboutOne = () => {

    const [selection, setSeletion] = useState("mission");
    useEffect(() => {
        Aos.init();
        window.scrollTo(0, 0);
    }, [])
    return (
        <>

            <section className='main'>
                <div className='about-us-page-container'>

                    <div class="get-in-touch">
                        <h3 className='max-width-title'>We help your business sustain and succeed in the long</h3>
                        <p>Skyblue Infotech is committed to developing digital solutions with Respect, Responsibility and Moderation.</p>
                    </div>
                    <img src={assetImages.aboutus01} alt="image" className='max-width-img' />
                </div>
            </section>
            <section className='our-story'>
                <div className='about-us-page-container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                            <h1 className='about-title-2'>About Us</h1>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-7 resposive-top-space'>
                            <p className='about-subtitle about-our-story'> Among the Professionals, Skyblue Infotech is the most trusted choice to build quality work at an affordable price. Our goal is to boost your Business through growing Internet services & technologies. We architect in rendering Dynamic, smart, and Professional Web-based solutions according to the current technologies prevailing in the market and make them more reliable for the Future. Punctuality with Perfection in the work is our big motive. Business Owners with great Visualization always look for Great designs with Great development that offers future services as well and so we are Pioneer in Giving the best technological support from start to end and even after the development.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className='our-story why-us'>
                <div className='about-us-page-container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                            <img src={assetImages.aboutus03} alt="img" className="max-width-img" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1500" />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                            <div className='why-us-div right' data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                <h1 className='about-title'>Work with one of the leading Digital Agency</h1>
                                <div className='row button-div'>
                                    <button className={`tab-btn ${selection === "mission" && "selected"}`} onClick={() => { setSeletion('mission') }}>Our Mission</button>
                                    <button className={`tab-btn ${selection === "vision" && "selected"}`} onClick={() => { setSeletion('vision') }}>Our Vision</button>
                                    {/* <button className={`tab-btn mr-0 ${selection === "goal" && "selected"}`} onClick={() => { setSeletion('goal') }}>Our Goal</button> */}
                                    {/* <div className='col-sm-3'></div> */}
                                </div>
                                {
                                    selection === "mission" && <>
                                        <h4 className='icon-title'>Our Mission</h4>
                                        <p className='about-subtitle about-our-story'>Innovative Thinking Is Central To What We Do, And We Take Responsibility For Every Project We Undertake. Our Company Is Committed To Transparency. We Have Integrated Groundbreaking Technologies Into Our Expansion Such As Digital Services, Website, Applications, Software’s, AR/ VR, Digital Marketing and more.</p>
                                        <p className='about-subtitle about-our-story'>As A Technical And Business Solutions Provider, We Are Equipped With A Wealth Of Expertise And Flexible Business Models. The Developers At Our Web & App Development Studio Maintain A Transparent Approach Throughout The Development Process.</p>
                                    </>
                                }
                                {
                                    selection === "vision" && <>
                                        <h4 className='icon-title'>Our Vision</h4>
                                        <p className='about-subtitle about-our-story'>Here, At Skyblue Infotech Our Goal Is To Assist Our Clients In All Aspects Of Their Business With The Aid Of Technology. With many Clients In different Countries, We Have Been In Business For Years. We Are Experts In Mobile & Web Development Technologies.</p>
                                        <p className='about-subtitle about-our-story'>Providing Clients With The Right Combination Of Sites And Apps Tailored To Their Needs Is Our Vision. We Aspire To Empower Ideas With The Latest Technology To Achieve Success In The Future.</p>
                                    </>
                                }
                                {
                                    selection === "goal" && <>
                                        <p className='about-subtitle about-our-story'>We’re a team of strategic digital marketing working globally with largest brands, We believe that progress only happens when you refused to play things safe. We help realize the client’s desire to build an application that can help users find clothes that fit their tastes. we also carry a simple and elegant design concept for this project,</p>
                                        <p className='about-subtitle about-our-story'>a service for making quotes, invoices and receipts quickly which is targeted to be used by freelancers and also small businesses, with several features in it that make it easier for users to create invoices.</p>
                                    </>
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section className='our-story why-us'>
                <div className='about-us-page-container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                            <div className='why-us-div left'>
                                <span className=''>Why Us</span>
                                <h1 className='about-title'>Unlock Revenue <br></br>for Your Business</h1>
                                <p className='about-subtitle about-our-story'>We provide quality software development services and can build web or mobile apps that perfectly fit your requirements. Competently. Hustle-free. In a timely fashion.</p>
                                <div className='about-us-li'>
                                    <span>+ Strong expertise</span>
                                    <span>+ Comprehensive approach</span>
                                    <span>+ Flexible Cooperation model</span>
                                    <span>+ Fair pricing</span>
                                    <span>+ Client-centric attitude</span>
                                    <span>+ Transparency</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-6' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                            <img src={assetImages.aboutus02} className="why-us-img max-width-img" alt="img" />

                        </div>
                    </div>

                </div>
            </section>
            <section className='our-story why-us bg-blue'>
                <div className='experience counter-section'>
                    <img src={assetImages.ab1} alt="" className='counter-bg1' />
                    <img src={assetImages.ab2} alt="" className='counter-bg2' />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6' data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                <div className='experience-div'><span>4</span><p>Years of Experience</p></div>
                            </div>
                            <div className='col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6' data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                <div className='experience-div'><span>18</span><p>Skilled Professionals</p></div>
                            </div>
                            <div className='col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6' data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                <div className='experience-div margin-bottom-responsive'><span>32</span><p>Visited Conferences</p></div>
                            </div>
                            <div className='col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6' data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                <div className='experience-div margin-bottom-responsive margin-bottom-responsive-360'><span>100</span><p>Projects Worldwide</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className='our-story why-us internation-brand'>
                <div className='about-us-page-container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='why-us-div'>
                                <span className=''>International Brands</span>
                                <h1 className='about-title mb-0'>We are happy to work with global largest brands</h1>
                                <div className='row'>
                                    <div className='logo-main'>
                                        <div className='logo-child'>
                                            <img alt="" src={assetImages.sec2_1}/>
                                        </div>
                                        <div className='logo-child'>
                                            <img alt="" src={assetImages.sec2_2}/>
                                        </div>
                                        <div className='logo-child'>
                                            <img alt="" src={assetImages.sec2_3}/>
                                        </div>
                                        <div className='logo-child'>
                                            <img alt="" src={assetImages.sec2_4}/>
                                        </div>
                                        <div className='logo-child'>
                                            <img alt="" src={assetImages.sec2_5}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Testimonial />
            <GetInTouchMarquee />
            <GreatTogether />
            <Footer />
        </>
    )
}
export default AboutOne;