import AboutOne from "../../components/AboutOne/AboutOne";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const AboutUs = () => {

    return (
        <>
            <Header />
            <AboutOne />
            <ScrollToTop />
        </>
    )
}

export default AboutUs;