import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import GetInTouchMarquee from '../../components/GetInTouchMarquee/GetInTouchMarquee';
import GreatTogether from '../../components/GreatTogether/GreatTogether';
import Header from '../../components/Header/Header';
import assetImages from '../../constant/enum';
import './OurProject.css';
import Aos from 'aos';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const OurProject = () => {

    useEffect(() => {
        Aos.init();
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Header />
            <section className='our-project-section'>
                <div className='our-project-container'>
                    <div className='get-in-touch'>
                        <h3>My Excellent <br />Works</h3>
                        <p className='w-100'><span className>All WORKS</span>  /  UI/UX DESIGN   /  WEB DESIGN  /   APP DESIGN   /  WEB APP DESIGN</p>
                    </div>
                    <div className='project-row first'>
                        <div className='d-flex-custom'>
                            <div className='project-content'>
                                <img src={assetImages.p1} alt="img" />
                                <h2 className='project-title'>Preparing the future of home made meals with Philips</h2>
                                <p className='project-desc'>Trusted by ambitious companies, brands, and founders, with 50+ mobile apps built.</p>
                                <svg className='project-arrow-svg' width="127" height="128" viewBox="0 0 127 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.8095 128L0 106.027L74.3487 31.1183L10.6836 31.1182L10.6839 0.000412489L127 0L126.999 117.279L96.2001 117.278L96.2005 53.0481L21.8095 128Z" fill="#dedede" />
                                </svg>
                            </div>
                            <div>
                                <img src={assetImages.project1} alt="image" className='project-img' />
                            </div>
                        </div>
                    </div>
                    <div className='project-row second '>
                        <div className='d-flex-custom'>
                            <div>
                                <img src={assetImages.project2} alt="image" className='project-img' />
                            </div>
                            <div className='project-content'>
                                <img src={assetImages.p1} alt="img" />
                                <h2 className='project-title'>Preparing the future of home made meals with Philips</h2>
                                <p className='project-desc'>Trusted by ambitious companies, brands, and founders, with 50+ mobile apps built.</p>
                                <svg className='project-arrow-svg' width="127" height="128" viewBox="0 0 127 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.8095 128L0 106.027L74.3487 31.1183L10.6836 31.1182L10.6839 0.000412489L127 0L126.999 117.279L96.2001 117.278L96.2005 53.0481L21.8095 128Z" fill="#dedede" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='project-row first'>
                        <div className='d-flex-custom'>
                            <div className='project-content'>
                                <img src={assetImages.p1} alt="img" />
                                <h2 className='project-title'>Preparing the future of home made meals with Philips</h2>
                                <p className='project-desc'>Trusted by ambitious companies, brands, and founders, with 50+ mobile apps built.</p>
                                <svg className='project-arrow-svg' width="127" height="128" viewBox="0 0 127 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.8095 128L0 106.027L74.3487 31.1183L10.6836 31.1182L10.6839 0.000412489L127 0L126.999 117.279L96.2001 117.278L96.2005 53.0481L21.8095 128Z" fill="#dedede" />
                                </svg>
                            </div>
                            <div>
                                <img src={assetImages.project3} alt="image" className='project-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GetInTouchMarquee />
            <GreatTogether />
            <Footer />
            <ScrollToTop />
        </>
    )
}

export default OurProject;