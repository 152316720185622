import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import GetInTouchMarquee from '../../components/GetInTouchMarquee/GetInTouchMarquee';
import GreatTogether from '../../components/GreatTogether/GreatTogether';
import Header from '../../components/Header/Header';
import assetImages from '../../constant/enum';
import './Services.css';
import Aos from 'aos';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init();
    }, [])
    return (<>
        <Header />
        <section className='our-service-page-section'>
            <div className='our-service-page-container'>
                <div className='get-in-touch'>
                    <h3>Our Services </h3>
                    <p>Great! We're excited to hear from you and let's start something special togerter. call us for any inquery.</p>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect">
                            <div className="tp-service-item-four sv-1-border sv-color-1 mb-30">
                                <div className="tp-service-item-four__img  mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">
                                        Product Management</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                Our web development process is simple and iterative: think, make, check, repeat. Our hybrid, agile approach to project management allows us to work faster, more efficiently, and with better results for our clients and their end-users.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-1">
                            <div className="tp-service-item-four sv-1-border sv-color-2 mb-30">
                                <div className="tp-service-item-four__img  mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">Web App
                                        Development</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-2">
                            <div className="tp-service-item-four sv-1-border sv-color-3 mb-30">
                                <div className="tp-service-item-four__img mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">Mobile App
                                        Development</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-3">
                            <div className="tp-service-item-four sv-1-border sv-color-4 mb-30">
                                <div className="tp-service-item-four__img mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">Software <br />
                                        Development</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-4">
                            <div className="tp-service-item-four sv-1-border sv-color-5 mb-30">
                                <div className="tp-service-item-four__img mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">UI UX Design</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-5">
                            <div className="tp-service-item-four sv-1-border sv-color-6 mb-30">
                                <div className="tp-service-item-four__img mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">AR VR</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tp-sv-border-effect sv-border-effect-6">
                            <div className="tp-service-item-four sv-1-border sv-color-7 mb-30">
                                <div className="tp-service-item-four__img mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">Digital
                                        Marketing</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-4 col-md-6" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500">
                        <div className="tp-sv-border-effect">
                            <div className="tp-service-item-four sv-1-border sv-color-1 mb-30">
                                <div className="tp-service-item-four__img  mb-40">
                                    <img src={assetImages.servicemail} alt="" />
                                </div>
                                <div className="tp-service-item-four__title">
                                    <h3 className="tp-sv-sm-title">Product Management</h3>
                                </div>
                            </div>
                            <div className='hover-div'>
                                <div className='hover-content'>
                                    Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition and create loyalty with great mobile applications. Get a competitive edge, increase your brand recognition.
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
        <GetInTouchMarquee />
        <GreatTogether />
        <Footer />
        <ScrollToTop />
    </>)
}

export default Services;