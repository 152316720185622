import { useEffect, useState } from 'react';
import assetImages from '../../constant/enum';
import './ContactUsComp.css';
import Aos from 'aos';

const ContactUsComp = () => {
    useEffect(() => {
        Aos.init();
    }, [])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        let validate = true;
        let err = {};
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (firstName === "" || firstName?.trim() === "") {
            err.firstName = 'Please Enter First Name.';
            validate = false;
        }
        if (lastName === "" || lastName?.trim() === "") {
            err.lastName = 'Please Enter Last Name.';
            validate = false;
        }
        if (email === "" || email?.trim() === "") {
            err.email = 'Please Enter Email Address.';
            validate = false;
        } else if (!email.match(validRegex)) {
            err.email = 'Please Enter Valid Email Address.';
            validate = false;
        }
        if (message === "" || message?.trim() === "") {
            err.message = 'Please Enter Message';
            validate = false;
        }
        setErrors(err);
        if (validate) {
            alert(1);
        }
    }
    return (
        <section className='conatct-us-section'>
            <div className='conatct-us-container'>
                <div className='get-in-touch'>
                    <h3>Let’s Get In Touch </h3>
                    <p>Great! We're excited to hear from you and let's start something special togerter. call us for any inquery.</p>
                </div>
                <div className='row contact-form-row'>
                    <div className='col-md-4 contact-left-part'>
                        <div className='d-flex bottom-space'>
                            <img src={assetImages.MapPoint} alt='map' />
                            <span className='contact-details'>Surat, Gujarat</span>
                        </div>
                        <div className='d-flex bottom-space'>
                            <img src={assetImages.call} alt='call' />
                            <span className='contact-details'>+91 9099396969</span>
                        </div>
                        <div className='d-flex'>
                            <img src={assetImages.mail} alt='mail' />
                            <span className='contact-details'>skyblueinfotech7@gmail.com</span>
                        </div>
                        <div className='contact-icon-row'>
                            <a href='https://www.facebook.com/Skytraveller1997?mibextid=ZbWKwL' target='_blank'>
                            <img src={assetImages.facebook} alt="img" className='icon-right-space' />
                            </a>
                            {/* <a href='' target='_blank'>
                            <img src={assetImages.twitter} alt="img" className='icon-right-space' />
                            </a> */}
                            <a href='https://instagram.com/skyblue_infotech?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target='_blank'>
                            <img src={assetImages.linkdin} alt="img" className='icon-right-space' />
                            </a>
                            <a href='https://instagram.com/skyblue_infotech?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target='_blank'>
                            <img src={assetImages.insta} alt="img" />
                            </a>
                        </div>

                    </div>
                    <div className='col-md-8 contact-left-right'>
                        <div className='drop-as-line'>
                            <h2>Drop Us a Line</h2>
                            <p>Your email address will not be published. Required fields are marked *</p>
                        </div>
                        <div className='input-row'>
                            <label className='input-label'>First Name</label>
                            <input type='text' className='custom-input' placeholder='Enter First name' onChange={(e) => { setFirstName(e.target.value); }} value={firstName} />
                            {errors && errors.firstName && <div className='error'>{errors.firstName}</div>}
                        </div>
                        <div className='input-row'>
                            <label className='input-label'>Last Name</label>
                            <input type='text' className='custom-input' placeholder='Enter Last Name' onChange={(e) => { setLastName(e.target.value); }} value={lastName} />
                            {errors && errors.lastName && <div className='error'>{errors.lastName}</div>}
                        </div>
                        <div className='input-row'>
                            <label className='input-label'>Email</label>
                            <input type='text' className='custom-input' placeholder='Enter Email' onChange={(e) => { setEmail(e.target.value); }} value={email} />
                            {errors && errors.email && <div className='error'>{errors.email}</div>}
                        </div>
                        <div className='input-row'>
                            <label className='input-label'>Message</label>
                            <textarea rows={6} className='custom-input message' placeholder='Enter your message' onChange={(e) => { setMessage(e.target.value); }} value={message}></textarea>
                            {errors && errors.message && <div className='error'>{errors.message}</div>}
                        </div>
                        <div className='submit-btn-row'>
                            <button className='contact-us-submit-btn' type='button' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUsComp;