import React from 'react';
import './Header.css';
import assetImages from '../../constant/enum';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = () => {
    const openNav = () => {
        if (document.getElementById("mySidenav"))
            document.getElementById("mySidenav").style.left = "0px";
        document.getElementById('body').style.overflow = 'hidden';
        document.getElementById('scroll-top').style.display = 'none';
    }

    const closeNav = () => {
        if (document.getElementById("mySidenav"))
            document.getElementById("mySidenav").style.left = "-500px";
        document.getElementById('body').style.overflow = '';
        if (window.scrollY > 60) {
            document.getElementById('scroll-top').style.display = 'flex';
        }
    };

    window.onscroll = function () {
        if (window.scrollY > 60) {
            if (document.getElementById('header')) {
                document.getElementById('header').classList.add('heder-top-close');
                if (document.getElementById('scroll-top'))
                    document.getElementById('scroll-top').style.display = 'flex';
            }
        }
        else {
            if (document.getElementById('scroll-top'))
                document.getElementById('scroll-top').style.display = 'none';
            if (document.getElementById('header'))
                document.getElementById('header').classList.remove('heder-top-close');
        }
    };
    const navigate = useNavigate();
    const handleNavigateContactus = () => {
        navigate('/contact-us');
    }
    const handleNavigateHome = () => {
        navigate('/');
    }
    return (
        <div id="header">
            <div className='header-container'>
                <div className='header-row'>
                    <div className='header-logo'>
                        <img src={assetImages.logo} className='header-logo-img' onClick={handleNavigateHome} />
                    </div>
                    <div className='header-menu'>
                        <ul className='header-menu-ul'>
                            <li className='active'><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/about-us">About</NavLink></li>
                            <li><NavLink to={"/services"}>Services</NavLink></li>
                            <li><NavLink to="/projects">Our Work</NavLink></li>
                            <li><NavLink to={"/faq"}>Faq</NavLink></li>
                            <li className='mr-0 position-relative'><button className='contact-btn' onClick={() => { handleNavigateContactus() }}><span className='demo'></span>Contact</button></li>
                        </ul>
                    </div>
                    <div className='responsive-menu-bar'>
                        <img src={assetImages.menuicon} onClick={openNav} alt="menu-icon" className='menu-icon-img' />
                    </div>
                    <ul id='mySidenav'>
                        <div className='mySidenav-header'>
                            <div className='header-logo'>
                                <img src={assetImages.logo} className='header-logo-img' onClick={handleNavigateHome} />
                            </div>
                            <div className='close-icon'>
                                <img src={assetImages.closeicon} onClick={closeNav} alt="close-icon" className="close-icon" />
                            </div>
                        </div>
                        <li className='active'><NavLink to="/" onClick={closeNav}>Home</NavLink></li>
                        <li><NavLink to="/about-us" onClick={closeNav}>About</NavLink></li>
                        <li><NavLink to={"/services"} onClick={closeNav}>Services</NavLink></li>
                        <li><NavLink to="/projects" onClick={closeNav}>Our Work</NavLink></li>
                        <li><NavLink to={"/faq"} onClick={closeNav}>Faq</NavLink></li>
                        <li><button className='contact-btn' onClick={() => { handleNavigateContactus(); closeNav(); }}>Contact</button></li>

                    </ul>

                </div>
            </div>
        </div >
    )
}

export default Header;