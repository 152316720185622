import './GetInTouchMarquee.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const GetInTouchMarquee = () => {
    const settings = {
        speed: 6000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false
    };
    return (
        <section className='get-in-touch-marqee-section'>
            <div className='marquee'>
                <Slider {...settings}>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                    <span>Get in touch</span>
                </Slider>
            </div>
        </section>
    )
}

export default GetInTouchMarquee;