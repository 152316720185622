import { useState } from 'react';
import assetImages from '../../constant/enum';
import './Testimonial.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
const Testimonial = () => {
    const [active, setActive] = useState(1);

    var settings = {
        dots: false,
        afterChange: current => { setActive(current + 1) },
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 551,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    arrows: false,
                }
            }
        ]
    };
    return (
        <>
            <div className="our-service-title-row testimonial-small-section" data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500">
                <div className="our-service-title-left">
                    <span className="our-service-small-title">Testimonials</span>
                    <h3 className="our-service-title testimonial-title">What Our Client Says</h3>
                </div>
                <div className="our-service-title-right">
                    <p className="our-service-desc">Client satisfaction is our ultimate goal, and we are very fortunate to provide our clients with distinctive, lasting and holistic solutions.</p>
                </div>
            </div>
            <section className='testimonil-section'>
                <div className='testimonil-container'>
                    <div>
                        <Slider {...settings} style={{ overflowX: "hidden" }}>
                            <div>

                                <div className='specific-testimonial'>
                                    {/* <img src={assetImages.t1} alt="testimonial" className='max-w-100' /> */}
                                    <div className='testimonail-content-div'>
                                        <div className='testimonail-content'>
                                            <img src={assetImages.quote} alt="quote" />
                                            <h4 className='testimonial-desc'>Skyblue Infotech successfully supported us to push our business forward. They bounce ideas off each other and give feedback and context to help in tech areas. Moreover, they’re approachable, responsive partners, and the team stands out for their expertise. It feels more like an internal team than an outsourcing relationship. Thank You!</h4>
                                            <p className='client-name'>Raghuvir Developers</p>
                                            {/* <div className='slide-counter'>
                                                <div className='slider-number'>1</div>
                                                <div className='slide-count-line'>
                                                    <div className='active-line'>
                                                    </div>
                                                </div>
                                                <div className='slider-number'>3</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='specific-testimonial'>
                                    {/* <img src={assetImages.t1} alt="testimonial" className='max-w-100' /> */}
                                    <div className='testimonail-content-div'>
                                        <div className='testimonail-content'>
                                            <img src={assetImages.quote} alt="quote" />
                                            <h4 className='testimonial-desc'>Skyblue Infotech offers a talented and dedicated team that has successfully helped expedite the development process. They have proven to be reliable and accommodating easily adjusting their workflow to match the requirements.</h4>
                                            <p className='client-name'>Rangildas</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='specific-testimonial'>
                                    {/* <img src={assetImages.t1} alt="testimonial" className='max-w-100' /> */}
                                    <div className='testimonail-content-div'>
                                        <div className='testimonail-content'>
                                            <img src={assetImages.quote} alt="quote" />
                                            <h4 className='testimonial-desc'>They met our needs, and the team was highly accommodating and diligent int thier work.</h4>
                                            <p className='client-name'>Ayursh</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </Slider>
                        <div className='slide-pagination'>
                            <div className='slide-counter'>
                                <div className='slider-number'>{active}</div>
                                <div className='slide-count-line'>
                                    <div className={`active-line ${active == 1 && 'a1'} ${active == 2 && 'a2'} ${active == 3 && 'a3'}`}>
                                    </div>
                                </div>
                                <div className='slider-number'>3</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial;