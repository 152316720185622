import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './faq.css';
import { useEffect, useState } from 'react';
import assetImages from '../../constant/enum';
import GetInTouchMarquee from '../../components/GetInTouchMarquee/GetInTouchMarquee';
import GreatTogether from '../../components/GreatTogether/GreatTogether';
import Aos from 'aos';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        Aos.init();
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Header />
            <section className='faq-section'>
                <div className='faq-container'>
                    <div className='get-in-touch' data-aos="zoom-in"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500">
                        <h3>Frequently asked question </h3>
                        <p>Great! We're excited to hear from you and let's start something special togerter. call us for any inquery.</p>
                    </div>
                    <div className='faq-list' data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                        <div className={`accordion__item  ${activeIndex === 0 && 'active'}`}>
                            <span className='faq-number'>1</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 0 && 'active'}`} onClick={() => { setActiveIndex(0); }}>
                                What kinds of clients do you work with?
                                    {
                                        activeIndex === 0 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 0 && 'active'}`} >
                                    <p>We focus on small to mid-market companies as well as non-profits but can deliver good value to organizations of just about any size. We have the experience and expertise to work with large enterprises but can also deliver world-class services at price points affordable by small companies.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 1 && 'active'}`}>
                            <span className='faq-number'>2</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 1 && 'active'}`} onClick={() => { setActiveIndex(1); }}>
                                How long it will take to build my website?
                                    {
                                        activeIndex === 1 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 1 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 2 && 'active'}`}>
                            <span className='faq-number'>3</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 2 && 'active'}`} onClick={() => { setActiveIndex(2); }}>
                                What if all I need is a makeover of my current website?
                                    {
                                        activeIndex === 2 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 2 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 3 && 'active'}`}>
                            <span className='faq-number'>4</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 3 && 'active'}`} onClick={() => { setActiveIndex(3); }}>
                                Does Augmented Reality help in Marketing?
                                    {
                                        activeIndex === 3 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 3 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 4 && 'active'}`}>
                            <span className='faq-number'>5</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 4 && 'active'}`} onClick={() => { setActiveIndex(4); }}>
                                What Industries Use AR/VR?
                                    {
                                        activeIndex === 4 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 4 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 5 && 'active'}`}>
                            <span className='faq-number'>6</span>
                            <div className='accordian-container'>
                                <div className={`accordion__item__header ${activeIndex === 5 && 'active'}`} onClick={() => { setActiveIndex(5); }}>
                                    How do you integrate Jetboost with Webflow?
                                    {
                                        activeIndex === 5 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 5 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`accordion__item  ${activeIndex === 6 && 'active'}`}>
                            <span className='faq-number'>7</span>
                            <div className='accordian-container border-0'>
                                <div className={`accordion__item__header ${activeIndex === 6 && 'active'}`} onClick={() => { setActiveIndex(6); }}>
                                    Are the artworks available as prints?
                                    {
                                        activeIndex === 6 ?
                                            <img src={assetImages.minus} alt="icon" /> :
                                            <img src={assetImages.plus} alt="icon" />
                                    }
                                </div>

                                <div className={`accordion__item__content ${activeIndex === 6 && 'active'}`} >
                                    <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GetInTouchMarquee />
            <GreatTogether />

            <Footer />
            <ScrollToTop />
        </>
    )
}

export default Faq;